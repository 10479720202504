import React, { useState } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import api, { url } from "../../../api";

export default function PrediosSelect({
  initialValue,
  Form,
  name,
  carrossel,
  classe,
  estado,
}) {
  const [predios, setPredios] = useState(initialValue);
  const [loadingPredios, setLoadingPredios] = useState(false);

  const searchPredios = async (value) => {
    setLoadingPredios(true);
    const { data } = await api.get(
      `/predios?nomePredio_contains=${value}${
        classe ? `&classe=${classe}` : ""
      }${estado ? `&estado=${estado}` : ""}&_limit=10`
    );
    setPredios(data);
    setLoadingPredios(false);
  };
  if (Form === undefined) return <></>;
  else
    return (
      <Form.Item initialValue={predios?.map((p) => p.id)} name={name}>
        <Select
          mode="tags"
          filterOption={false}
          notFoundContent={loadingPredios && <LoadingOutlined />}
          showSearch
          style={{ width: "100%" }}
          onSearch={searchPredios}
        >
          {carrossel
            ? predios
                ?.filter((p) => p.capa != undefined && p.capa != null)
                .map((p) => (
                  <Select.Option key={p.id} value={p.id}>
                    {carrossel ? (
                      <img src={`${url}${p.capa.url}`} />
                    ) : (
                      p.nomePredio
                    )}
                  </Select.Option>
                ))
            : predios?.map((p) => (
                <Select.Option key={p.id} value={p.id}>
                  {carrossel ? (
                    <img src={`${url}${p.capa.url}`} />
                  ) : (
                    p.nomePredio
                  )}
                </Select.Option>
              ))}
        </Select>
      </Form.Item>
    );
}
PrediosSelect.propTypes = {
  initialValue: PropTypes.any,
  carrossel: PropTypes.any,
  Form: PropTypes.any,
  name: PropTypes.any,
  classe: PropTypes.any,
  estado: PropTypes.any,
};
